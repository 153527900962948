import { createStore } from 'vuex';

export default createStore({
  state: {
    showDrawer: false,
    pageId: -1,
    pageTitle: '',
    shoppingCartProducts: [],
    selectedAddressId: 0,
    selectedIBOId: 0,
    selectedProductId: 0,
    selectedAudioPackId: 0,
    selectedEventId: 0,
    selectedExternalLinkId: 0,
    selectedAlbumInfo: {
      id: 0,
      quantity: 0,
      title: '',
      author: '',
      description: '',
      image: '',
    },
    selectedOrderId: 0,
    userIsAuthenticated: false,
    userToken: '',
    userTokenValidUntil: '',
    iboId: 0,
    iboInfo: {
      id: 0,
      number: '',
      name: '',
      email: '',
      phone: '',
      achievementLevelId: 0,
      achievementLevel: '',
      icon: 'fa-solid fa-user',
      imageUrl: '',
    },
    nowPlayingInfo: {
      id: 0,
      intervalId: 0,
      albumId: 0,
      audioId: 0,
      position: 0,
      speed: 1,
      title: '',
      author: '',
      hash: '',
      image: '',
      icon: '',
      color: '',
      durationSeconds: 0,
      durationMinutes: -1,
    },
    selectedOrderYear: 0,
    selectedOrderMonth: 0,
    selectedOrderTypes: 0,
  },
  getters: {
    showDrawer(state) {
      return state.showDrawer;
    },
    pageHasDrawer(state) {
      return state.pageId > 0;
    },
    pageId(state) {
      return state.pageId;
    },
    pageTitle(state) {
      return 'Rhinos Admin - ' + state.pageTitle;
    },
    shoppingCartProducts(state) {
      return state.shoppingCartProducts;
    },
    productsOnCart(state) {
      return state.shoppingCartProducts.length;
    },
    iboInfo(state) {
      return state.iboInfo;
    },
    selectedAddressId(state) {
      return state.selectedAddressId;
    },
    selectedAlbumInfo(state) {
      return state.selectedAlbumInfo;
    },
    selectedOrderId(state) {
      return state.selectedOrderId;
    },
    selectedIBOId(state) {
      return state.selectedIBOId;
    },
    nowPlayingInfo(state) {
      return state.nowPlayingInfo;
    },
    nowPlayingIntervalId(state) {
      return state.nowPlayingInfo.intervalId;
    },
    userIsAuthenticated(state) {
      state.userIsAuthenticated = localStorage.getItem('userIsAuthenticated');
      return state.userIsAuthenticated;
    },
    iboId(state) {
      return state.iboId;
    },
    selectedProductId(state) {
      return state.selectedProductId;
    },
    selectedAudioPackId(state) {
      return state.selectedAudioPackId;
    },
    selectedEventId(state) {
      return state.selectedEventId;
    },
    selectedOrderYear(state) {
      return state.selectedOrderYear;
    },
    selectedOrderMonth(state) {
      return state.selectedOrderMonth;
    },
    selectedOrderTypes(state) {
      return state.selectedOrderTypes;
    },
    selectedExternalLinkId(state) {
      return state.selectedExternalLinkId;
    },
  },
  mutations: {
    toggleShowDrawer(state) {
      if (state.pageId > 0) {
        state.showDrawer = !state.showDrawer;
      } else {
        state.showDrawer = false;
      }
    },
    updatePageId(state, payload) {
      state.pageId = payload;
    },
    updatePageTitle(state, payload) {
      state.pageTitle = payload;
    },
    updateShowDrawer(state, payload) {
      state.showDrawer = payload;
    },
    updateShoppingCartProducts(state, payload) {
      state.shoppingCartProducts.push(payload);
    },
    updateIBOInfo(state, payload) {
      state.iboInfo = {
        id: payload.id,
        number: payload.number,
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        achievementLevel: payload.achievementLevel,
        achievementLevelId: payload.achievementLevelId,
        icon:
          payload.achievementLevelId > 1
            ? 'fa-solid fa-gem'
            : 'fa-solid fa-user',
        imageUrl: payload.imageUrl,
      };
      localStorage.setItem('iboInfo', JSON.stringify(state.iboInfo));
      localStorage.setItem('iboId', JSON.stringify(payload.id));
      state.iboId = payload.id;
    },
    updateSelectedAddressId(state, payload) {
      state.selectedAddressId = payload;
    },
    updateSelectedIBOId(state, payload) {
      state.selectedIBOId = payload;
    },
    updateSelectedAlbumInfo(state, payload) {
      state.selectedAlbumInfo = {
        id: payload.id,
        quantity: payload.quantity,
        title: payload.title,
        author: payload.author,
        description: payload.description,
        image: payload.image,
      };
    },
    updateSelectedOrderId(state, payload) {
      state.selectedOrderId = payload;
    },
    updateNowPlayingInfo(state, payload) {
      state.nowPlayingInfo = {
        id: payload.id,
        intervalId: payload.intervalId,
        albumId: payload.albumId,
        audioId: payload.audioId,
        position: payload.position,
        speed: payload.speed,
        title: payload.title,
        author: payload.author,
        hash: payload.hash,
        image: payload.image,
        icon: payload.icon,
        color: payload.color,
        durationSeconds: payload.durationSeconds,
        durationMinutes: payload.durationMinutes,
      };
    },
    updateNowPlayingIntervalId(state, payload) {
      state.nowPlayingInfo.intervalId = payload;
    },
    updateUserIsAuthenticated(state, payload) {
      state.userIsAuthenticated = payload;
      localStorage.setItem('userIsAuthenticated', payload);
    },
    resetStoreInfo(state) {
      state.showDrawer = false;
      state.pageTitle = '';
      state.shoppingCartProducts = [];
      state.selectedAddressId = 0;
      state.selectedAlbumInfo = {
        id: 0,
        quantity: 0,
        title: '',
        author: '',
        description: '',
        image: '',
      };
      state.selectedOrderId = 0;
      state.userIsAuthenticated = false;
      state.userToken = '';
      state.userTokenValidUntil = '1979-01-12 00:00:01';
      state.iboInfo = {
        id: 0,
        number: '',
        name: '',
        email: '',
        phone: '',
        achievementLevelId: 0,
        achievementLevel: '',
        icon: 'fa-solid fa-user',
        imageUrl: '',
      };
      state.nowPlayingInfo = {
        id: 0,
        intervalId: 0,
        albumId: 0,
        audioId: 0,
        position: 0,
        speed: 1,
        title: '',
        author: '',
        hash: '',
        image: '',
        icon: '',
        color: '',
        durationSeconds: 0,
        durationMinutes: -1,
      };
      localStorage.clear();
      state.iboId = 0;
    },
    loadStoreInfo(state) {
      if (localStorage.getItem('userIsAuthenticated')) {
        state.userIsAuthenticated = true;
        state.iboId = localStorage.getItem('iboId');
        state.iboInfo = JSON.parse(localStorage.getItem('iboInfo'));
      } else {
        state.userIsAuthenticated = false;
        state.iboId = 0;
        state.iboInfo = {
          id: 0,
          number: '',
          name: '',
          email: '',
          phone: '',
          achievementLevelId: 0,
          achievementLevel: '',
          icon: 'fa-solid fa-user',
          imageUrl: '',
        };
      }
    },
    emptyShoppingCart(state) {
      state.shoppingCartProducts = [];
    },
    updateSelectedProductId(state, payload) {
      state.selectedProductId = payload;
    },
    updateSelectedAudioPackId(state, payload) {
      state.selectedAudioPackId = payload;
    },
    updateSelectedEventId(state, payload) {
      state.selectedEventId = payload;
    },
    updateSelectedOrderYear(state, payload) {
      state.selectedOrderYear = payload;
    },
    updateSelectedOrderMonth(state, payload) {
      state.selectedOrderMonth = payload;
    },
    updateSelectedOrderTypes(state, payload) {
      state.selectedOrderTypes = payload;
    },
    updateSelectedExternalLinkId(state, payload) {
      state.selectedExternalLinkId = payload;
    },
  },
  actions: {
    async updateShowDrawer({ commit }, payload) {
      commit('updateShowDrawer', payload);
    },
    async updatePageId({ commit }, payload) {
      commit('updatePageId', payload);
    },
    async updatePageTitle({ commit }, payload) {
      commit('updatePageTitle', payload);
    },
    async toggleShowDrawer({ commit }) {
      commit('toggleShowDrawer');
    },
    async updateShoppingCartProducts({ commit }, payload) {
      commit('updateShoppingCartProducts', payload);
    },
    async updateIBOInfo({ commit }, payload) {
      commit('updateIBOInfo', payload);
    },
    async updateSelectedAddressId({ commit }, payload) {
      commit('updateSelectedAddressId', payload);
    },
    async updateSelectedIBOId({ commit }, payload) {
      commit('updateSelectedIBOId', payload);
    },
    async updateSelectedAlbumInfo({ commit }, payload) {
      commit('updateSelectedAlbumInfo', payload);
    },
    async updateSelectedOrderId({ commit }, payload) {
      commit('updateSelectedOrderId', payload);
    },
    async updateNowPlayingInfo({ commit }, payload) {
      commit('updateNowPlayingInfo', payload);
    },
    async updateNowPlayingIntervalId({ commit }, payload) {
      commit('updateNowPlayingInfoIntervalId', payload);
    },
    async updateUserIsAuthenticated({ commit }, payload) {
      commit('updateUserIsAuthenticated', payload);
    },
    async resetStoreInfo({ commit }) {
      commit('resetStoreInfo');
    },
    async loadStoreInfo({ commit }) {
      commit('loadStoreInfo');
    },
    async emptyShoppingCart({ commit }) {
      commit('emptyShoppingCart');
    },
    async updateSelectedProductId({ commit }, payload) {
      commit('updateSelectedProductId', payload);
    },
    async updateSelectedAudioPackId({ commit }, payload) {
      commit('updateSelectedAudioPackId', payload);
    },
    async updateSelectedEventId({ commit }, payload) {
      commit('updateSelectedEventId', payload);
    },
    async updateSelectedOrderYear({ commit }, payload) {
      commit('updateSelectedOrderYear', payload);
    },
    async updateSelectedOrderMonth({ commit }, payload) {
      commit('updateSelectedOrderMonth', payload);
    },
    async updateSelectedOrderTypes({ commit }, payload) {
      commit('updateSelectedOrderTypes', payload);
    },
    async updateSelectedExternalLinkId({ commit }, payload) {
      commit('updateSelectedExternalLinkId', payload);
    },
  },
  modules: {},
});
